<template>
     <div style="background-color:#EFF1F2;height:100%;width:100%">
      <div style="padding:20px;padding-top:5%;">
      <v-img src="../assets/gimbook_logo.svg" style="max-width:200px; max-height:300px;" class="mx-auto"></v-img>
     <v-card 
      class="mx-auto mb-4 pt-4 pb-4"
      style="padding:60px;min-width:250px;margin-top:6%;"
      max-width="600"
      height="350"
    >
    <p style="color:red ;margin:10px 150px;font-size:18px;" v-if="this.$store.state.Auth.errorMessage"> {{this.$store.state.Auth.errorMessage}}</p>
    <p style="color:green;width:100%;margin:1px 80px;font-size:18px;" v-if="this.$store.state.Auth.success"> {{this.$store.state.Auth.success}}</p>
        
    
    
      <v-card-text 
        class="text-center pb-1 pt-2" 
        style="margin-bottom:50px;font-size:24px;text-align:center">
    
    <p>      Reset Password
    </p>
    
      </v-card-text>
      <form @submit="onSubmit" >
        <div class="input-icons"> 
            <i>
              <v-icon class="icon" large>mdi-account</v-icon>
            </i> 
            <input 
              class="input-field" 
              type="text" 
              v-model="phone" 
              placeholder="Username"> 
        </div>
        <div style="margin-top:50px;">
          <v-btn
            x-large
            type="submit"
            dark
            style="background-color:white;border:1px solid #363740;color:#363740;width:100%;margin:auto;text-transform:capitalize;font-weight:700;"
            >
            Send Reset Link
          </v-btn>
        </div>  
        
      </form>
    </v-card>
  </div>
     </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    name:"PasswordReset",
    data(){
        return {
            phone:'',
            successMessage:""
        }
    },
    methods:{
        ...mapActions('Auth',['resetPassword']),
        onSubmit(e){
            e.preventDefault();
            console.log(this.phone, 'in login')
            this.resetPassword({'phone':this.phone});
            this.phone="";
            this.password="";
            
        }
    },
    mounted(){
      this.$store.state.Auth.access=false;
    }
}
</script>

<style scoped>

        .input-icons i { 
            position: absolute; 
        } 
          
        .input-icons { 
            width: 100%; 
            margin-bottom: 25px; 
            
        } 
          
        .icon { 
            padding: 8px; 
            color: darkgrey;
            background-color:lightgray; 
            min-width: 20px; 
            border:1px solid darkgrey;
            text-align: center; 
        } 
          
        .input-field { 
          border:1px solid grey;
            width: 100%;
            height:55px; 
            font-size:18px;
            padding-left: 60px; 
            text-align: left; 
        } 
          
        h2 { 
            color: green; 
        } 


</style>